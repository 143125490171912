import React from 'react';

type ImageProps = {
  src: string;
  alt: string;
  width?: string;
  height?: string;
  style?: object;
  className?: string;
  align?: 'top' | 'middle' | 'bottom' | 'left' | 'right' | undefined;
  loading?: 'lazy' | 'eager' | undefined;
  onError?: () => void;
  imageProps?: object;
};

const Image = ({
  alt = 'image',
  className = '',
  src = '',
  width = '',
  height = '',
  style = {},
  loading = undefined,
  imageProps = {},
  onError = () => {
    {
    }
  },
}: ImageProps) => {
  return (
    <img
      src={src}
      alt={alt}
      className={className}
      width={width}
      height={height}
      loading={loading}
      style={style}
      onError={onError}
      {...imageProps}
    />
  );
};

export default Image;
