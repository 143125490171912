'use client';

import React, { useCallback, useState } from 'react';
import NylasContext from '@src/contexts/connectNylas';
import { getNylasSession, setNylasCode } from '@src/services/marketingCRM';
import { showSuccessMsg } from '@src/utils/notifications';
import { SUCCESS_MESSAGES } from '@src/constants/messages';
import { APP_ROUTE } from '@src/constants/routes';
import { useRouter } from 'next/navigation';

const NylasProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();
  /**
   * Connects to Nylas by redirecting user to the auth_url, obtained from
   * {@link getNylasSession}.
   *
   * @returns {Promise<void>} Resolves when the redirect is successful
   * @throws {Error} If there is an error while fetching the auth_url
   */
  const connectNylas = async () => {
    try {
      setIsLoading(true);

      const { auth_url = '' } = await getNylasSession();

      if (auth_url) {
        window.location.assign(auth_url);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Sets the Nylas authorization code on the server, and triggers the onNylasConnect
   * callback.
   *
   * @param {string} code - The Nylas authorization code to set
   * @returns {Promise<void>} Resolves when the Nylas authorization code is set
   * @throws {Error} If there is an error while setting the Nylas authorization code
   */
  const sentNylasCode = useCallback(
    async ({
      code,
      onNylasConnect,
    }: {
      code: string;
      onNylasConnect?: () => void;
    }) => {
      try {
        setIsLoading(true);
        await setNylasCode(code);
        showSuccessMsg(SUCCESS_MESSAGES.NYLAS_ACCOUNT_LINKED);
        router.push(APP_ROUTE.AGENT_DASHBOARD_CRM_MARKETING);
        if (onNylasConnect) onNylasConnect();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const NylasContextValue = {
    isLoading,
    connectNylas,
    sentNylasCode,
  };

  return (
    <NylasContext.Provider value={NylasContextValue}>
      {children}
    </NylasContext.Provider>
  );
};

export default NylasProvider;
