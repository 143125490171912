'use client';
import React, { useState } from 'react';
import LoaderContext, { LoaderContextProps } from '@src/contexts/loader';

interface LoaderProviderProps {
  children: React.ReactNode;
}

const LoaderProvider: React.FC<LoaderProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loaderType, setLoaderType] = useState('');

  const showLoader = (type = ''): void => {
    setIsLoading(true);
    setLoaderType(type);
  };

  const hideLoader = (): void => {
    setIsLoading(false);
    setLoaderType('');
  };

  const loaderContextValue: LoaderContextProps = {
    isLoading,
    loaderType,
    showLoader,
    hideLoader,
  };

  return (
    <LoaderContext.Provider value={loaderContextValue}>
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderProvider;
