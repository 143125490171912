import { AgentRegisterPayload } from '@src/interfaces/app/auth/register';
import { loginFormTypes } from '@src/interfaces/app/auth/login';
import {
  AGENT_URLS,
  AUTH_API_URLS,
  LANDLORD_URLS,
  RENTER_URLS,
} from '@src/constants/urls';
import { httpGet, httpPatch, httpPost } from '@src/utils/http';
import {
  CheckEmailPayload,
  CheckPhonePayload,
  ExpressRenterRegisterPayload,
} from '@src/interfaces/app/auth/register/renter/renterRegister';
import { RegisterRenterPayload } from '@src/interfaces/components/wizard/register/details';
import { ZEME_USERS_ROLE } from '@src/constants/configs';
import { getRefreshToken, getUserId } from '@src/utils/localStorage';

/**
 * This method is used to perform API request for checking user identification
 *
 * @param {String} params.email
 * @param {String} params.password
 * @returns Promise
 */
const loginUser = async (params: loginFormTypes) => {
  const response = await httpPost(AUTH_API_URLS.login, params);
  return response;
};

/**
 * Logs out the user by sending a POST request to the logout API endpoint.
 *
 * @return {Promise<object>} The response object from the logout API endpoint.
 */
const logoutUser = async () => {
  const params = {
    refresh_token: getRefreshToken(),
  };
  const response = await httpPost(AUTH_API_URLS.logout, params);
  return response;
};

/**
 * Deletes a user by sending a request to the server with the user's password.
 *
 * @param {string} password - The password of the user to be deleted
 * @return {Promise<any>} The response from the server after deleting the user
 */
const deleteUser = async (password: string) => {
  const params = {
    password,
  };
  const response = await httpPost(AUTH_API_URLS.DELETE_USER, params);
  return response;
};

/**
 * This method is used to perform API request for register an agent
 *
 * @param {String} params.general_information.first_name
 * @param {String} params.general_information.middle_name
 * @param {String} params.general_information.last_name
 * @param {String} params.general_information.phone_number
 * @param {Boolean} params.general_information.verified
 * @param {String} params.general_information.email
 * @param {String} params.general_information.password
 * @param {String} params.general_information.notifications_token
 * @param {String} params.agent_information.profile_picture
 * @param {String} params.agent_information.company_name
 * @returns Promise
 */
const registerAgent = async (
  params: AgentRegisterPayload,
  isLandlord: boolean
) => {
  const response = await httpPost(
    isLandlord ? AUTH_API_URLS.REGISTER_LANDLORD : AUTH_API_URLS.REGISTER_AGENT,
    params
  );
  return response;
};

/**
 * This method is used to perform API request for register renter
 *
 * @param {GeneralInformation} params.general_information
 * @param {RenterInformation} params.renter_information
 * @returns Promise
 */
const registerRenter = async (
  params: RegisterRenterPayload | ExpressRenterRegisterPayload
) => {
  const response = await httpPost(AUTH_API_URLS.REGISTER_RENTER, params);
  return response;
};

/**
 * This method is used to perform API request for update [patch] renter
 *
 * @param {GeneralInformation} params.general_information
 * @param {RenterInformation} params.renter_information
 * @param {string} id
 * @returns Promise
 */
const editRenter = async (
  params: Partial<RegisterRenterPayload>,
  id: string
) => {
  const response = await httpPatch(
    AUTH_API_URLS.EDIT_RENTER.replace('#RENTER_ID#', String(id || '')),
    params
  );
  return response;
};

/**
 * This method is used to perform API request for update [patch] agent
 *
 * @param {AgentRegisterPayload} params
 * @param {string} id
 * @returns Promise
 */
const editAgent = async (
  params: Partial<RegisterRenterPayload>,
  id: string
) => {
  const response = await httpPatch(
    AUTH_API_URLS.EDIT_AGENT.replace('#AGENT_ID#', String(id || '')),
    params
  );
  return response;
};

/**
 * This method is used to perform API request for update [patch] agent
 *
 * @param {AgentRegisterPayload} params
 * @param {string} id
 * @returns Promise
 */
const editLandlord = async (
  params: Partial<RegisterRenterPayload>,
  id: string
) => {
  const response = await httpPatch(
    AUTH_API_URLS.EDIT_LANDLORD.replace('#LANDLORD_ID#', String(id || '')),
    params
  );
  return response;
};

/**
 * This method is used to perform API request check email is exist or not
 * @param {CheckEmailPayload} params.email
 * @returns Promise
 */
const checkEmailExist = async (params: CheckEmailPayload) => {
  const response = await httpPost(AUTH_API_URLS.CHECK_EMAIL, params);
  return response;
};

/**
 * This method is used to perform API request check Phone number is exist or not
 * @param {CheckEmailPayload} params.phone
 * @returns Promise
 */
const checkPhoneExist = async (params: CheckPhonePayload) => {
  const response = await httpPost(AUTH_API_URLS.CHECK_PHONE, params);
  return response;
};

/**
 * This method is used to perform API request to send OTP to user.
 * @param {params} params.phone_number
 * @returns Promise
 */
const updatePhoneNumber = async (params: { phone_number: string }) => {
  const response = await httpPost(AUTH_API_URLS.UPDATE_PHONE_NUMBER, params);
  return response;
};

/**
 * This method is used to perform API request to send OTP to user.
 * @param {params} params.phone_number
 * @returns Promise
 */
const sendOtpToUser = async (params: { phone_number: string }) => {
  const response = await httpPost(AUTH_API_URLS.SEND_OTP, params);
  return response;
};

/**
 * This method is used to perform API request to send OTP to user.
 * @param {params} params.phone_number
 * @param {params} params.code
 * @returns Promise
 */
const verifyOtpForUser = async (params: {
  phone_number: string;
  code: string;
}) => {
  const response = await httpPost(AUTH_API_URLS.VERIFY_OTP, params);
  return response;
};

/**
 * This method is used to perform API request to get active user profile.
 * @param {string} userType
 * @param {string} userId
 * @returns Promise
 */
const getUserProfile = async (userType: string, userId: string) => {
  let url = '';

  // Get user profile for Renter, Landlord and Agent.
  switch (userType) {
    case ZEME_USERS_ROLE.AGENT:
      url = AGENT_URLS.GET_PROFILE;
      break;
    case ZEME_USERS_ROLE.LANDLORD:
      url = LANDLORD_URLS.GET_PROFILE;
      break;

    default:
      url = RENTER_URLS.GET_PROFILE;
      break;
  }

  // Bind agent/renter id in URL.
  url = url.replace('#ID#', String(userId));

  const response = await httpGet(url);
  return response;
};

/**
 * Retrieves the public profile of an agent based on their ID.
 *
 * @param {string} id - The ID of the agent.
 * @return {Promise<any>} A promise that resolves to the response from the server.
 */
const getAgentPublicProfile = async (id: string) => {
  const url = AGENT_URLS.AGENT_PUBLIC_PROFILE.replace('#ID#', String(id));

  const response = await httpGet(url);
  return response;
};

/**
 * This method is used to perform API request to update renter profile.
 * @returns Promise
 */
const updateKidsAndPets = async (params: {
  renter_information: {
    household_information: {
      total_occupants: number;
      small_dogs: number;
      medium_dogs: number;
      large_dogs: number;
      cats: number;
      other_pets: number;
    };
  };
}) => {
  const id = getUserId();

  const response = await httpPatch(
    `${RENTER_URLS.UPDATE_PROFILE.replace('#ID#', String(id))}`,
    params
  );
  return response;
};

/**
 * This method is used to perform API request to get subscription plans
 * @returns Promise
 */
const fetchSubscriptionPlans = async () => {
  const url = `${AUTH_API_URLS.GET_SUBSCRIPTION_PLANS}`;

  const response = await httpGet(url);
  return response;
};

/**
 * Applies a coupon to a specific plan.
 *
 * @param {string} planId - The ID of the plan to apply the coupon to.
 * @param {string} code - The coupon code to apply.
 * @return {Promise<any>} A promise that resolves with the response from the server.
 */
const applyCoupon = async (planId: string, code: string) => {
  const params = {
    plan_id: planId,
    coupon_code: code,
  };
  const url = `${AUTH_API_URLS.APPLY_COUPON}`;

  const response = await httpPost(url, params, { hideError: true });
  return response;
};

/**
 * Creates an agent subscription by applying a coupon code to a plan.
 *
 * @param {string} planId - The ID of the plan.
 * @param {string} couponCode - The coupon code to apply.
 * @param {string} token - The token to apply finix payment.
 * @return {Promise<any>} - The response from the server.
 */
const createAgentSubscription = async (
  planId: string,
  couponCode: string | null | undefined,
  token?: string,
  type?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  address?: any
) => {
  const params: {
    plan_id: string;
    coupon_code?: string;
    token?: string;
    type?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    address?: any;
  } = {
    plan_id: planId,
  };

  if (couponCode) {
    params.coupon_code = couponCode;
  }

  if (token) {
    params.token = token;
  }

  if (type) {
    params.type = type;
  }

  if (address) {
    params.address = {
      line1: address.address1,
      city: address.locality,
      region: address.administrativeArea,
      country: address.countryCode,
      postal_code: address.postalCode,
    };
  }

  const url = `${AUTH_API_URLS.CREATE_AGENT_SUBSCRIPTION}`;

  const response = await httpPost(url, params);
  return response;
};

/**
 * Fetches a token with all permissions.
 *
 * @return {Promise<any>} The response from the server.
 */
const fetchTokenWithAllPermissions = async () => {
  const url = `${AUTH_API_URLS.GET_UNLIMITED_ACCESS_TOKEN}`;

  const response = await httpPost(url, {});
  return response;
};

/**
 * Fetch Fair Housing Template from API
 *
 */
const getFairHousingTemplate = async (agentId: string) => {
  const url = AUTH_API_URLS.GET_FAIR_HOUSING_TEMPLATE;

  const params = {
    agent_id: agentId,
  };

  const response = await httpPost(url, params);

  return response;
};

const loginWithGoogle = async (token: string) => {
  const url = AUTH_API_URLS.LOGIN_WITH_GOOGLE;
  const params = {
    token,
  };
  const response = await httpPost(url, params);
  return response;
};

const loginWithApple = async (
  token: string,
  firstName: string,
  lastName: string,
  email: string
) => {
  const url = AUTH_API_URLS.LOGIN_WITH_APPLE;
  const params: {
    token: string;
    first_name?: string;
    last_name?: string;
    email?: string;
  } = {
    token,
  };

  if (firstName) params['first_name'] = firstName;

  if (lastName) params['last_name'] = lastName;

  if (email) params['email'] = email;

  const response = await httpPost(url, params);
  return response;
};

export {
  loginUser,
  logoutUser,
  deleteUser,
  registerAgent,
  checkEmailExist,
  checkPhoneExist,
  registerRenter,
  editRenter,
  editAgent,
  editLandlord,
  getUserProfile,
  getAgentPublicProfile,
  updateKidsAndPets,
  sendOtpToUser,
  verifyOtpForUser,
  updatePhoneNumber,
  fetchSubscriptionPlans,
  applyCoupon,
  createAgentSubscription,
  fetchTokenWithAllPermissions,
  getFairHousingTemplate,
  loginWithGoogle,
  loginWithApple,
};
