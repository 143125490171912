import(/* webpackMode: "eager" */ "/var/www/rentzeme/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"poppins\"}");
import(/* webpackMode: "eager" */ "/var/www/rentzeme/app/node_modules/react-toastify/dist/react-toastify.esm.mjs");
import(/* webpackMode: "eager" */ "/var/www/rentzeme/app/node_modules/react-toastify/dist/ReactToastify.css");
import(/* webpackMode: "eager" */ "/var/www/rentzeme/app/src/app/globals.css");
import(/* webpackMode: "eager" */ "/var/www/rentzeme/app/src/components/nylasConnectionModal/index.tsx");
import(/* webpackMode: "eager" */ "/var/www/rentzeme/app/src/providers/loader.tsx");
import(/* webpackMode: "eager" */ "/var/www/rentzeme/app/src/providers/nylas.tsx");
import(/* webpackMode: "eager" */ "/var/www/rentzeme/app/src/providers/propertyList.tsx");
import(/* webpackMode: "eager" */ "/var/www/rentzeme/app/src/providers/registeRenter.tsx");
import(/* webpackMode: "eager" */ "/var/www/rentzeme/app/src/providers/savedPropertyList.tsx");
import(/* webpackMode: "eager" */ "/var/www/rentzeme/app/src/providers/theme.tsx");
import(/* webpackMode: "eager" */ "/var/www/rentzeme/app/src/providers/userProfile.tsx")