/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import React, { useState } from 'react';
import RegisterStepContext, {
  RegisterStepContextDataValue,
  defaultRegisterStepContextValue,
} from '@src/contexts/registerStepper';

interface RegisteRenterProviderProps {
  children: React.ReactNode;
}

const RegisteRenterProviderProps: React.FC<RegisteRenterProviderProps> = ({
  children,
}) => {
  const [values, setValues] = useState<RegisterStepContextDataValue>(
    defaultRegisterStepContextValue
  );

  return (
    <RegisterStepContext.Provider value={{ values, setValues }}>
      {children}
    </RegisterStepContext.Provider>
  );
};

export default RegisteRenterProviderProps;
