import { Property } from '@src/interfaces/app/propertyLists';
import React, { createContext } from 'react';

export interface SavedPropertyContextProps {
  savedPropertyList: Property[];
  setSavedPropertyList: React.Dispatch<React.SetStateAction<Property[]>>;
}
const SavedPropertyListingContext = createContext<SavedPropertyContextProps>({
  savedPropertyList: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSavedPropertyList: () => {},
});

export default SavedPropertyListingContext;
