import React, { createContext } from 'react';
import { Property } from '@src/interfaces/app/propertyLists';

export interface PropertyContextProps {
  propertyList: Property[];
  setPropertyList: React.Dispatch<React.SetStateAction<Property[]>>;
  singleProperty: Property[];
  setSingleProperty: React.Dispatch<React.SetStateAction<Property[]>>;
}

const PropertyListingContext = createContext<PropertyContextProps>({
  propertyList: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPropertyList: () => {},

  singleProperty: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSingleProperty: () => {},
});

export default PropertyListingContext;
