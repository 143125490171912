import React from 'react';
import Image from '@src/components/coreUI/image';
import { loaderType } from '@src/interfaces/components/loader';

/**
 * Component to display loader and block screen with black background
 *
 * @param { Boolean } props.isVisible
 * @returns Node
 */
const Loader = ({ isVisible = false }: loaderType) => {
  return isVisible ? (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white/75 flex flex-col items-center justify-center">
      <div className="flex justify-center items-center">
        <div className="relative">
          <div className="pageloader"></div>
          <Image
            src="/zeme-icon.png"
            alt="Loader"
            className="w-10 absolute top-[20px] left-[18px]"
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default Loader;
