'use client';

import { Dialog, Transition } from '@headlessui/react';
import Cross from '@src/assets/svg/cross';
import { ZEME_USERS_ROLE } from '@src/constants/configs';
import { getNylasConnection } from '@src/services/marketingCRM';
import { getUserType, isLoggedIn } from '@src/utils/localStorage';
import { usePathname, useSearchParams } from 'next/navigation';
import React, { Fragment, useEffect, useState } from 'react';
import ConnectNylas from '../crm/connectNylas';
import useProfile from '@src/hooks/useProfile';
import logo from 'public/zeme-colored-logo.png';
import Image from 'next/image';

function NylasConnectionModal({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();
  const { profile } = useProfile();
  const searchParams = useSearchParams();

  // States
  const [isOpenNylasModal, setIsOpenNylasModal] = useState(false);

  /**
   * Retrieves the Nylas connection status from the server, and updates the component's
   * state with the response.
   *
   * @returns {Promise<void>} A Promise that resolves when the Nylas connection status is
   * fetched from the server.
   */
  const fetchNylasConnection = async () => {
    try {
      const { valid = false, has_grant = false } = await getNylasConnection();

      if (has_grant && !valid) {
        setIsOpenNylasModal(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Checks if the user is logged in and is not a renter.
   * If true, fetches the Nylas connection status from the server.
   */
  const checkAbilityForNylas = () => {
    const code = searchParams.get('code');

    if (
      !code &&
      isLoggedIn() &&
      getUserType() !== ZEME_USERS_ROLE.RENTER &&
      (profile?.agentInformation?.nylasGrant ||
        profile?.landlordInformation?.nylasGrant)
    ) {
      fetchNylasConnection();
    }
  };

  // detect route changes
  useEffect(() => {
    checkAbilityForNylas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pathname,
    searchParams,
    profile?.agentInformation?.nylasGrant,
    profile?.landlordInformation?.nylasGrant,
  ]);

  return (
    <>
      {isOpenNylasModal ? (
        <Transition.Root show={isOpenNylasModal} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-[100]"
            onClose={() => {
              setIsOpenNylasModal(false);
            }}
          >
            {/* Modal black shadow */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black-dark/30 transition-opacity backdrop-blur-[1px]" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-hidden">
              <div className="flex min-h-full justify-center text-center items-center p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative rounded-xl bg-white text-left shadow-xl transition-all w-[calc(100%-32px)] sm:my-8 sm:w-full max-w-xl">
                    <>
                      <a
                        onClick={() => {
                          setIsOpenNylasModal(false);
                          return;
                        }}
                        className="absolute block w-[1.875rem] h-[1.875rem] top-[-10px] right-[-7px] rounded-full text-blue-dark hover:text-blue-dark/70 focus-visible:outline-none cursor-pointer"
                      >
                        <Cross />
                      </a>
                      <div className="flex flex-col py-5 px-2 overflow-y-auto overscroll-contain">
                        <div className="flex justify-center my-7">
                          <Image src={logo} alt="Zeme Logo" width={150} />
                        </div>
                        <div className="flex justify-center mb-7">
                          <h4 className="text-4xl font-semibold text-center text-red-500">
                            Action Required:
                            <br />
                            <span className="text-black-dark">
                              Relink Your CRM
                            </span>
                          </h4>
                        </div>
                        <div className="flex flex-wrap justify-center mb-7">
                          Your CRM is unlinked. Reconnect within
                          <span className="flex text-red-500">
                            &nbsp;24 hours&nbsp;
                          </span>
                          to avoid missing new leads.
                        </div>
                        <div className="flex justify-center mb-7">
                          <div className="h-[3px] w-[56px] bg-gradient-to-r from-primary-dark to-primary-light" />
                        </div>
                        <div className="flex justify-center items-center">
                          <ConnectNylas
                            isHideNylasImage
                            onNylasConnect={() => {
                              return;
                            }}
                            title=""
                            subTitle=""
                            btnText="Relink CRM"
                            btnTheme="primary"
                          />
                        </div>
                      </div>
                    </>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : null}
      {children}
    </>
  );
}

export default NylasConnectionModal;
