'use client';
import React, { useCallback, useEffect, useState } from 'react';
import ProfileContext, { ProfileContextProps } from '@src/contexts/userProfile';
import { getUserProfile } from '@src/services/auth';
import { transformRenterProfile } from '@src/utils/helper';
import { getItem, isLoggedIn } from '@src/utils/localStorage';
import { ZEME_USER_DATA } from '@src/constants/localStorage';
import { RenterProfile } from '@src/interfaces/profile';
import { useRouter } from 'next/navigation';
import { APP_ROUTE } from '@src/constants/routes';

interface ProfileProviderProps {
  children: React.ReactNode;
}

const ProfileProvider: React.FC<ProfileProviderProps> = ({ children }) => {
  const { push } = useRouter();
  const [profile, setUserProfile] = useState<RenterProfile | null>(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(isLoggedIn());

  /**
   * This method is used to fetch user profile from server.
   */
  const fetchMyProfile = useCallback(
    // eslint-disable-next-line no-unused-vars
    async (callback?: (profile: RenterProfile | null) => void) => {
      try {
        const { id, account_type }: { id: string; account_type: string } =
          getItem(ZEME_USER_DATA) || {
            id: '',
            account_type: '',
          };
        const res = await getUserProfile(
          account_type.toLowerCase(),
          String(id || '')
        );
        const profile = transformRenterProfile(res);
        setProfile(profile);

        if (callback) callback(profile);
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  /**
   * Call on component mount
   */
  useEffect(() => {
    if (isUserLoggedIn) {
      fetchMyProfile((profile) => {
        if (!profile?.generalInformation?.verifiedSms) {
          push(APP_ROUTE.VERIFY_CODE);
          return;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMyProfile, isUserLoggedIn]);

  /**
   * This is method is used to set profile context.
   * @param {object} profile
   */
  const setProfile = (profile: RenterProfile | null): void => {
    setUserProfile(profile);
  };

  const profileContextValue: ProfileContextProps = {
    profile,
    setProfile,
    fetchMyProfile,
    setIsUserLoggedIn,
    isUserLoggedIn,
  };

  return (
    <ProfileContext.Provider value={profileContextValue}>
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
