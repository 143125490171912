import React from 'react';

const Cross = (props: object) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="17"
        cy="17"
        r="16"
        fill="white"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M12.6002 11.2543L17.0001 15.6538L21.4 11.2676C21.5752 11.0894 21.8169 10.9927 22.0666 11.001C22.5681 11.0334 22.9676 11.4328 23 11.9342C23.0024 12.175 22.906 12.4063 22.7333 12.5741L18.3201 17.0003L22.7333 21.4264C22.906 21.5942 23.0024 21.8255 23 22.0663C22.9676 22.5677 22.5681 22.9671 22.0666 22.9995C21.8169 23.0078 21.5752 22.9111 21.4 22.7329L17.0001 18.3468L12.6136 22.7329C12.4384 22.9111 12.1966 23.0078 11.9469 22.9995C11.4356 22.9728 11.0269 22.5642 11.0003 22.053C10.9979 21.8122 11.0943 21.5809 11.2669 21.413L15.6802 17.0003L11.2536 12.5741C11.0857 12.404 10.9943 12.1731 11.0003 11.9342C11.0327 11.4328 11.4321 11.0334 11.9336 11.001C12.1813 10.9893 12.4228 11.081 12.6002 11.2543Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Cross;
