'use client';

import React, { useState } from 'react';
import { Property } from '@src/interfaces/app/propertyLists';
import PropertyListingContext from '@src/contexts/propertyList';

interface PropertyProviderProps {
  children: React.ReactNode;
}

const PropertyProvider: React.FC<PropertyProviderProps> = ({ children }) => {
  const [propertyList, setPropertyList] = useState<Property[]>([]);
  const [singleProperty, setSingleProperty] = useState<Property[]>([]);

  return (
    <PropertyListingContext.Provider
      value={{
        propertyList,
        setPropertyList,
        singleProperty,
        setSingleProperty,
      }}
    >
      {children}
    </PropertyListingContext.Provider>
  );
};

export default PropertyProvider;
