'use client';

import { useContext, useMemo } from 'react';
import ProfileContext from '@src/contexts/userProfile';

function useProfile() {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('useProfile must be used within a app');
  }

  return useMemo(
    () => ({
      ...context,
    }),
    [context]
  );
}

export default useProfile;
