'use client';

import React, { useState } from 'react';
import SavedPropertyListingContext from '@src/contexts/savedPropertyList';
import { Property } from '@src/interfaces/app/propertyLists';

const SavedPropertyProvider = ({ children }: { children: React.ReactNode }) => {
  const [savedPropertyList, setSavedPropertyList] = useState<Property[]>([]);
  return (
    <SavedPropertyListingContext.Provider
      value={{ savedPropertyList, setSavedPropertyList }}
    >
      {children}
    </SavedPropertyListingContext.Provider>
  );
};

export default SavedPropertyProvider;
