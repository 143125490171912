'use client';
import { createContext, Context, Dispatch, SetStateAction } from 'react';

export interface ThemeContextValue {
  theme: string;
  setTheme: Dispatch<SetStateAction<string>>;
}

const ThemeContext: Context<ThemeContextValue> =
  createContext<ThemeContextValue>({} as ThemeContextValue);

export default ThemeContext;
