import { RenterProfile } from '@src/interfaces/profile';
import { Dispatch, SetStateAction, createContext } from 'react';

export interface ProfileContextProps {
  profile: RenterProfile | null;
  // eslint-disable-next-line no-unused-vars
  setProfile: (profile: RenterProfile | null) => void;
  // eslint-disable-next-line no-unused-vars
  fetchMyProfile: (callback?: (profile: RenterProfile | null) => void) => void;
  setIsUserLoggedIn: Dispatch<SetStateAction<boolean>>;
  isUserLoggedIn: boolean;
}

const ProfileContext = createContext<ProfileContextProps | null>(null);

export default ProfileContext;
