'use client';
import React, { useState } from 'react';
import ThemeContext, { ThemeContextValue } from '@src/contexts/theme';

function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [theme, setTheme] = useState('');

  const contextValue: ThemeContextValue = {
    theme,
    setTheme,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
}

export default ThemeProvider;
